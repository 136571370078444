<script>
import { usePartyStore } from "../stores/PartyStore";
import GermanyStates from "../codelists/states/de.statesGermany.json";
import AustriaStates from "../codelists/states/de.statesAustria.json";
import SwitzerlandStates from "../codelists/states/de.statesSwitzerland.json";

export default {
  name: "HeaderSearch",

  async created() {
    this.partyStore = usePartyStore();
  },
  props: {
    dataReady:Boolean,
  },
  data() {
    return {
    GermanyStates,
    AustriaStates,
    SwitzerlandStates,

      //shortsearch
      formShortSearch: {
        shortsearchVal: null,
        searchtopicShort: "ISBN",

        // Isbn and Vknr red Buttons
        isbn: null,
        vknr: null,

        // input fields
        VKNRInput: null,
        validateVKNRempty: null,
        validateISBNempty: null,        
        validateVKNRInputSize: null,
        validateVKNRInputNumbers: null,
        validateVKNRInputStartwith: null,
        validateVKNRInputStartwithRange100to174: null,
        validateVKNRInputStartwithRange175to179: null,
        validateVKNRInputStartwithRange180to184: null,
        validateVKNRInputStartwithRange185to189: null,
        validateVKNRInputStartwithRange200to599: null,

        ISBNInput: null,
        isbnTrimReplace: null,
        validateISBNInputLength: null,
        validateISBNInputNumbers: null,
      },

      //fullsearch
      formFullSearch: {
        searchtopic: null,
        companyname: null,
        city: null,
        businesstype: null,
        BAG: false,
        DOI: null,
        GLN: null,
        taxID: null,
        taxIDCheckDE: null,
        taxIDCheckATU: null,
        taxIDCheckCHE: null,
        taxIDCheckDefault: null,
        country: [],
        vlbtitle: null,
        vlbtitleMIN: null,
        vlbtitleMAX: null,
        federalstatesDE: [],
        federalstatesAU: [],
        federalstatesCH: [],
        federalstatesLI: [],
        showGermany: false,
        showAustria: false,
        showSwitzerland: false,
        showLiechtenstein: false,

        // validation
        GLNInputSize: null,
        DOIInputSize: null,
      },

      // hidden tabs
      tabcompanyname: false,
      tabregion: false,
      tabbusinesstype: false,
      tabvlbtitle: false,
      tabcodenumbers: false,

      allFormInputsAreCleared:false,
      emptySubmit:false,
      searchstarted:false,

      partyStore: null,
    };
  },

  components: {},


  methods: {
    /// SEARCH FORM VALIDATIONS
    validateShortSearchVKNR() {
      // console.log("validateShortSearchVKNR function:: ");

      if (this.formShortSearch.VKNRInput) {
        this.formShortSearch.validateVKNRInputSize = this.formShortSearch.VKNRInput.trim().length > 4 && this.formShortSearch.VKNRInput.trim().length < 8;
        // console.log("this.formShortSearch.validateVKNRInputSize " +  this.formShortSearch.validateVKNRInputSize);

        this.formShortSearch.validateVKNRInputNumbers = /^[0-9]*$/.test( this.formShortSearch.VKNRInput.trim());
        // console.log( "this.formShortSearch.validateVKNRInputNumbers " + this.formShortSearch.validateVKNRInputNumbers);

        this.formShortSearch.validateVKNRInputStartwith = /^1|^2|^3|^4|^5/.test(  this.formShortSearch.VKNRInput.trim());
        // console.log( "this.formShortSearch.validateVKNRInputStartwith " +  this.formShortSearch.validateVKNRInputStartwith);

        // console.log(this.formShortSearch.validateVKNRInputSize &&  this.formShortSearch.validateVKNRInputNumbers &&  this.formShortSearch.validateVKNRInputStartwith);
        return (
          this.formShortSearch.validateVKNRInputSize &&
          this.formShortSearch.validateVKNRInputNumbers &&
          this.formShortSearch.validateVKNRInputStartwith
      );
      }
      else {
      //  console.log("validateShortSearchVKNR function:: else leeeer");
        this.formShortSearch.validateVKNRempty=true;
      }
      // console.log("validate vknr function::suchstring " + this.formShortSearch.VKNRInput);

    },

    validateShortSearchISBN() {
      // console.log("validateShortSearchISBN function:: ");
      if (this.formShortSearch.ISBNInput) {
         this.formShortSearch.isbnTrimReplace = this.formShortSearch.ISBNInput.trim().replace(/ /g, '').replace(/-/g, '');
         // console.log("isbnTrimReplace" + this.formShortSearch.isbnTrimReplace);

        this.formShortSearch.validateISBNInputLength = this.formShortSearch.isbnTrimReplace.length > 5 && this.formShortSearch.isbnTrimReplace.length < 14;
        this.formShortSearch.validateISBNInputNumbers = /^[0-9]*$/.test(this.formShortSearch.isbnTrimReplace);
        // console.log("ISBN Länge:  " + this.formShortSearch.validateISBNInputLength);
        // console.log("ISBN Numbers: " + this.formShortSearch.validateISBNInputNumbers);
        return (
          this.formShortSearch.validateISBNInputLength &&
          this.formShortSearch.validateISBNInputNumbers

        );
      }
      else {
       // console.log("validateShortSearchISBN function:: else leeeer");
        this.formShortSearch.validateISBNempty=true;        
      }      
    },

    validateFullSearchGLN() {
      // console.log("validateFullSearchGLN function:: " + this.formFullSearch.GLN);
      if (this.formFullSearch.GLN) {
        // GLN only numbers
        this.formFullSearch.GLNInputSize = /^[0-9]*$/.test(this.formFullSearch.GLN);         
        return this.formFullSearch.GLNInputSize;
      } else {
        // GLN field is empty
        this.formFullSearch.GLNInputSize = null;
        return true;
      }
    },

    validateFullSearchDOI() {
      // console.log("validateFullSearchDOI function:: " + this.formFullSearch.DOI);
      if (this.formFullSearch.DOI) {
          // check input
          // Doi is always like this 10.1234
          if (this.formFullSearch.DOI.length > 6 && this.formFullSearch.DOI.startsWith("10.")) {
            // check part after 10. if only numbers
            var numericPart = this.formFullSearch.DOI.slice(3, 7);
            var doicheck =  /^[0-9]{4}$/.test(numericPart);
            return this.formFullSearch.DOIInputSize = doicheck;
        }
        else {
            // Doi is not correct
            return this.formFullSearch.DOIInputSize = false;
        }

      } else {
        // DOI field is empty
        this.formFullSearch.DOIInputSize = null;
        return true;
      }
    },    

    validateFullSearchTaxID() {
      // console.log("validateFullSearchTaxID function:: ");
      if (this.formFullSearch.taxID) {
        // replace string -,.
      this.formFullSearch.taxID = this.formFullSearch.taxID.replace(/[ \-.,]/g, '');
        switch (true) {
          // example: DE123456789
          case /^DE|^de[0-9]*$/.test(this.formFullSearch.taxID):
            this.formFullSearch.taxIDCheckDE =
              this.formFullSearch.taxID.length > 10 &&
              this.formFullSearch.taxID.length < 12;
            // console.log("case de " + this.formFullSearch.taxIDCheckDE);
            this.formFullSearch.taxIDCheckATU = null;
            this.formFullSearch.taxIDCheckCHE = null;
            this.formFullSearch.taxIDCheckDefault = null;
            return this.formFullSearch.taxIDCheckDE;

          // example: ATU12345678
          case /^ATU|^atu[0-9]*$/.test(this.formFullSearch.taxID):
            this.formFullSearch.taxIDCheckATU =
              this.formFullSearch.taxID.length > 10 &&
              this.formFullSearch.taxID.length < 12;
            // console.log("case atu " + this.formFullSearch.taxIDCheckATU);
            this.formFullSearch.taxIDCheckDE = null;
            this.formFullSearch.taxIDCheckCHE = null;
            this.formFullSearch.taxIDCheckDefault = null;
            return this.formFullSearch.taxIDCheckATU;

          // example: CHE12345678
          case /^CHE|^che[0-9]*$/.test(this.formFullSearch.taxID):
            this.formFullSearch.taxIDCheckCHE =
              this.formFullSearch.taxID.length > 11 &&
              this.formFullSearch.taxID.length < 13;
            // console.log("case che " + this.formFullSearch.taxIDCheckCHE);
            this.formFullSearch.taxIDCheckATU = null;
            this.formFullSearch.taxIDCheckDE = null;
            this.formFullSearch.taxIDCheckDefault = null;
            return this.formFullSearch.taxIDCheckCHE;

          default:
            this.formFullSearch.taxIDCheckDefault =
              this.formFullSearch.taxID.length > 10 &&
              this.formFullSearch.taxID.length < 12;
            // console.log("case default " + this.formFullSearch.taxIDCheckDefault);
            this.formFullSearch.taxIDCheckATU = null;
            this.formFullSearch.taxIDCheckDE = null;
            this.formFullSearch.taxIDCheckCHE = null;
            return this.formFullSearch.taxIDCheckDefault;
        }
      } else {
        // TAX field is empty
        this.formFullSearch.taxIDCheckDE = null;
        this.formFullSearch.taxIDCheckATU = null;
        this.formFullSearch.taxIDCheckCHE = null;
        this.formFullSearch.taxIDCheckDefault = null;
        return true;
      }
    },

    /// SEARCH FORM SUBMISSIONS

    submitShortSearch() {
      //console.log("submitShortSearch function:: ");
      // first clear all inputs in full search
      this.clearAllFormFull();
      if (this.formShortSearch.searchtopicShort == "VKNR") {
        if (this.validateShortSearchVKNR()) {
           console.log("shortsearch with VKNR");
          // console.log("vknrinput: " + this.formShortSearch.VKNRInput);
          // console.log("searchtopicShort: " + this.formShortSearch.searchtopicShort);
          // send submit to backend
          this.searchVKNR(this.formShortSearch.VKNRInput.trim());
          return;
        } else {
          // console.log("vknr größe ist falsch: " + this.formShortSearch.validateVKNRInputSize);
          // console.log("vknr enthält nicht nur zahlen: " + this.formShortSearch.validateVKNRInputNumbers);
          return (
            this.formShortSearch.validateVKNRInputSize,
            this.formShortSearch.validateVKNRInputNumbers
          );
        }
      }

      if (this.formShortSearch.searchtopicShort == "ISBN") {
           //console.log("shortsearch with ISBN");        
        if (this.validateShortSearchISBN()) {
          //TODO: Why is this called twice?
          //console.log("shortsearch with ISBN---2");      
          this.searchISBN(this.formShortSearch.isbnTrimReplace);
          return;
        }
      }

      return (
        this.formShortSearch.searchtopicShort,
        // do we need this? 
        //  this.formShortSearch.VKNRInput.trim(),
        this.formShortSearch.isbnTrimReplace
      );
    },

clearAllSearches(){
      // function is called on reset button under form
      //  clear all inputs of short form  
    this.clearAllFormShort();
            
      //  clear all inputs of full form  
    this.clearAllFormFull(); 

      // show hint all cleared
    return this.allFormInputsAreCleared=true;

    },

clearAllFormShort(){
      // short
        this.formShortSearch.shortsearchVal= null,
        this.formShortSearch.searchtopicShort= "ISBN",

        // Isbn and Vknr red Buttons
        this.formShortSearch.isbn= null,
        this.formShortSearch.vknr= null,

        // input fields
        this.formShortSearch.ISBNEmpty= null,
        this.formShortSearch.vknrEmpty= null,                
        this.formShortSearch.VKNRInput= null,
        this.formShortSearch.validateVKNRInputSize= null,
        this.formShortSearch.validateVKNRInputNumbers= null,
        this.formShortSearch.validateVKNRInputStartwith= null,
        this.formShortSearch.validateVKNRInputStartwithRange100to174= null,
        this.formShortSearch.validateVKNRInputStartwithRange175to179= null,
        this.formShortSearch.validateVKNRInputStartwithRange180to184= null,
        this.formShortSearch.validateVKNRInputStartwithRange185to189= null,
        this.formShortSearch.validateVKNRInputStartwithRange200to599= null,

        this.formShortSearch.ISBNInput= null,
        this.formShortSearch.isbnTrimReplace= null,
        this.formShortSearch.validateISBNInputLength= null,
        this.formShortSearch.validateISBNInputNumbers= null
      },

clearAllFormFull(){

      this.allFormInputsAreCleared=false;
      // full      
      this.formFullSearch.searchtopic= null,
      this.formFullSearch.companyname= null,
      this.formFullSearch.city= null,
      this.formFullSearch.businesstype= null,
      this.formFullSearch.BAG= false,
      this.formFullSearch.DOI= null,
      this.formFullSearch.GLN= null,
      this.formFullSearch.taxID= null,
      this.formFullSearch.taxIDCheckDE= null,
      this.formFullSearch.taxIDCheckATU= null,
      this.formFullSearch.taxIDCheckCHE= null,
      this.formFullSearch.taxIDCheckDefault= null,
      this.formFullSearch.country= [],
      this.formFullSearch.vlbtitle= null,
      this.formFullSearch.vlbtitleMIN= null,
      this.formFullSearch.vlbtitleMAX= null,
      this.formFullSearch.federalstatesDE= [],
      this.formFullSearch.federalstatesAU= [],
      this.formFullSearch.federalstatesCH= [],
      this.formFullSearch.federalstatesLI= [],
      this.formFullSearch.showGermany= false,
      this.formFullSearch.showAustria= false,
      this.formFullSearch.showSwitzerland= false,
      this.formFullSearch.showLiechtenstein= false,

     // validation
      this.formFullSearch.GLNInputSize= null,
      this.formFullSearch.DOIInputSize= null

    },


    async submitFullSearch() {
    this.searchstarted=true;
     
    // Clear all in ShortSearch inputs
    this.clearAllFormShort();
    // hide message
    this.allFormInputsAreCleared=false;

      // console.log("submitFullSearch!");
      // console.log("gln value " + this.formFullSearch.GLN);
      // console.log("gln check " + this.validateFullSearchGLN());
      // console.log("doi value " + this.formFullSearch.DOI);
      // console.log("doi check " + this.validateFullSearchDOI());
      // console.log("tax id check " + this.validateFullSearchTaxID());

      if (this.validateFullSearchGLN() && this.validateFullSearchTaxID()) {

        let searchParams = new Map();

        if (this.formFullSearch.companyname) {
          searchParams.set('companyName', this.formFullSearch.companyname.trim());
        }

        if (this.formFullSearch.country.length > 0) {
          searchParams.set('country', this.formFullSearch.country.toString().trim());
        }

        if (this.formFullSearch.federalstatesDE.length > 0) {
          searchParams.set('federalStatesDE', this.formFullSearch.federalstatesDE.toString().trim());
        }

        if (this.formFullSearch.federalstatesAU.length > 0) {
          searchParams.set('federalStatesAU', this.formFullSearch.federalstatesAU.toString().trim());
        }

        if (this.formFullSearch.federalstatesCH.length > 0) {
          searchParams.set('federalStatesCH', this.formFullSearch.federalstatesCH.toString().trim());
        }

        if (this.formFullSearch.federalstatesLI.length > 0) {
          searchParams.set('federalStatesLI', this.formFullSearch.federalstatesLI.toString().trim());
        }

        if (this.formFullSearch.city) {
          searchParams.set('city', this.formFullSearch.city.trim());
        }

        if (this.formFullSearch.BAG) {
          searchParams.set('BAG', this.formFullSearch.BAG);
        }

        if (this.formFullSearch.GLN) {
          searchParams.set('GLN', this.formFullSearch.GLN.trim());
        }

        if (this.formFullSearch.taxID) {
          searchParams.set('taxId', this.formFullSearch.taxID.trim());
        }

        if (this.formFullSearch.DOI) {
          searchParams.set('DOI', this.formFullSearch.DOI.trim());
        }

        if (this.formFullSearch.businesstype) {
          // Christine: Your turn to make it clean
          switch (this.formFullSearch.businesstype) {
            case 'publisher':
              searchParams.set('businessType', 1);
              break;
            case 'bookstores':
              searchParams.set('businessType', 2);
              break;
            case 'distributor':
              searchParams.set('businessType', 3);
              break;
            case 'singleISBNpublisher':
              searchParams.set('businessType', 11);
              break;
            case 'serviceprovider':
              searchParams.set('businessType', 12);
              break;
            default:
              searchParams.set('businessType', this.formFullSearch.businesstype);
          }
        }

        if (this.formFullSearch.vlbtitleMIN) {
          searchParams.set('vlbTitlesMin', this.formFullSearch.vlbtitleMIN);
        }

        if (this.formFullSearch.vlbtitleMAX) {
          searchParams.set('vlbTitlesMax', this.formFullSearch.vlbtitleMAX);
        }

        if (searchParams.size == 0) {
          // show message to customer if nothing is checked in searchform
          this.emptySubmit=true;
          return;
        }
        //hide message to customer
        this.emptySubmit=false;
        await this.partyStore.fullSearch(searchParams);
      } 
    },

    clearFormShortVkNr() {
      // this clears Input of VKNR when Red button ISBN is clicked
      // and clear all inputs of full form
      // console.log("clearFormShortVkNr function::");
      this.clearAllFormFull();
      this.formShortSearch.validateVKNRempty=false;  
      this.formShortSearch.VKNRInput = "";
      this.formShortSearch.validateVKNRInputSize = true;
      this.formShortSearch.validateVKNRInputNumbers = true;
      this.formShortSearch.validateVKNRInputStartwith = true;
      this.formShortSearch.validateVKNRInputStartwithRange100to174 = null;
      this.formShortSearch.validateVKNRInputStartwithRange175to179 = null;
      this.formShortSearch.validateVKNRInputStartwithRange180to184 = null;
      this.formShortSearch.validateVKNRInputStartwithRange185to189 = null;
      this.formShortSearch.validateVKNRInputStartwithRange200to599 = null;
    },

    clearFormShortISBN() {
      // this clears Input of ISBN when Red button VKNR is clicked
      // and clear all inputs of full form
      this.clearAllFormFull();      
      // console.log("clearFormShortISBN function::");
      this.formShortSearch.validateISBNempty=false;  
      this.formShortSearch.ISBNInput = "";
      this.formShortSearch.validateISBNInputLength=true;
    },

    

    /// TABS
    showTabcompanyname() {
      // console.log("tabcompanyname ");
      this.tabcompanyname = true;
    },
    showTabregion() {
      // console.log("tabregion ");
      this.tabregion = true;
    },
    showTabvlbtitle() {
      // console.log("tabvlbtitle ");
      this.tabvlbtitle = true;
    },
    showTabbusinesstype() {
      // console.log("tabbusinesstype ");
      this.tabbusinesstype = true;
    },
    showTabcodenumbers() {
      // console.log("tabcodenumbers ");
      this.tabcodenumbers = true;
    },
    hideAllTabs() {
      this.tabcompanyname = false;
      this.tabbusinesstype = false;
      this.tabregion = false;
      this.tabvlbtitle = false;
      this.tabcodenumbers = false;
      // if Tab VLB Titel is disabled then clear inputs of formFullSearch.vlbtitle
      this.checkVLBtitel();
    },

    checkVLBtitel() {
      if (this.$refs.tabvlbtitle.disabled) {
        this.formFullSearch.vlbtitleMIN = null;
        this.formFullSearch.vlbtitleMAX = null;
      }
    },

    checkVLBtitelnumber() {
      if (this.formFullSearch.vlbtitleMIN && this.formFullSearch.vlbtitleMAX) {
        if((this.formFullSearch.vlbtitleMAX < this.formFullSearch.vlbtitleMIN)) {
       //  console.log("Max kleiner Min");
        this.formFullSearch.vlbtitleMIN = null;
        this.formFullSearch.vlbtitleMAX = null;
        }
      }
    },
    deselectCountry(remove) {
      // DE
      if (remove == "D") {
        this.formFullSearch.country.forEach((item, index) => {
          if (item == "D") {
            this.formFullSearch.country.splice(index, 1);
            this.formFullSearch.showGermany = false;
            this.formFullSearch.federalstatesDE.length = 0;
          }
        });
        // console.log("delete D");
      }
      // AU
      if (remove == "A") {
        this.formFullSearch.country.forEach((item, index) => {
          if (item == "A") {
            this.formFullSearch.country.splice(index, 1);
            this.formFullSearch.showAustria = false;
            this.formFullSearch.federalstatesAU.length = 0;
          }
        });
        // console.log("delete A");
      }
      // CH
      if (remove == "CH") {
        this.formFullSearch.country.forEach((item, index) => {
          if (item == "CH") {
            this.formFullSearch.country.splice(index, 1);
            this.formFullSearch.showSwitzerland = false;
            this.formFullSearch.federalstatesCH.length = 0;
          }
        });
        // console.log("delete CH");
      }
      // LI
      if (remove == "FL") {
        this.formFullSearch.country.forEach((item, index) => {
          if (item == "FL") {
            this.formFullSearch.country.splice(index, 1);
            this.formFullSearch.showLiechtenstein = false;
            this.formFullSearch.federalstatesLI.length = 0;
          }
        });
        // console.log("delete FL");
      }
    },

    changeCountryTab() {
      // D
      if (this.$refs.checkboxgermany.checked) {
        this.formFullSearch.showGermany = true;
        // console.log("D checkbox true");
      } else {
        this.formFullSearch.showGermany = false;
        this.formFullSearch.federalstatesDE.length = 0;
        // console.log("D checkbox false");
      }
      // A
      if (this.$refs.checkboxaustria.checked) {
        this.formFullSearch.showAustria = true;
        // console.log("A checkbox true");
      } else {
        this.formFullSearch.showAustria = false;
        this.formFullSearch.federalstatesAU.length = 0;
        // console.log("A checkbox false");
      }
      // CH
      if (this.$refs.checkboxswitzerland.checked) {
        this.formFullSearch.showSwitzerland = true;
        // console.log("CH checkbox true");
      } else {
        this.formFullSearch.showSwitzerland = false;
        this.formFullSearch.federalstatesCH.length = 0;
        // console.log("CH checkbox false");
      }
      // FL
      if (this.$refs.checkboxliechtenstein.checked) {
        this.formFullSearch.showLiechtenstein = true;
        // console.log("FL checkbox true");
      } else {
        this.formFullSearch.showLiechtenstein = false;
        this.formFullSearch.federalstatesLI.length = 0;
        // console.log("FL checkbox false");
      }
    },

    setDoiInput() {
      this.formFullSearch.DOI = '10.';
    },

    async searchVKNR(vknr) {
      await this.partyStore.searchVknr(vknr);
    },

    async searchISBN(isbn) {
      await this.partyStore.searchISBN(isbn);
    },
  },

  computed: {},
};
</script>

<template>
  <div class="container max-w-5xl mx-auto">
    <div class="md:grid grid-cols-2 justify-between w-auto ">
      <div class="w-full">
        <a href="/" class=""><img class="mt-4 mb-5" src="../assets/adbLogoNew.png" alt="AdB Logo" /></a>
      </div>
      <!---------------- Short SEARCH ------------------------->
      <form @submit.prevent="">
        <div class="w-full mt-5 md:mt-0">
          <ul class="grid gap-1 md:grid-cols-6">
            <li class="md:col-span-3">
              <input type="radio" id="ISBN" value="ISBN" v-model="formShortSearch.searchtopicShort" class="hidden peer"
                @click="clearFormShortVkNr()" />
              <label for="ISBN"
               checked class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 rounded-r cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
                <div class="block">
                  <div class="text-lg font-semibold">
                    {{ $t("Search.ISBN") }}
                  </div>
                </div>
              </label>
            </li>
            <li class="md:col-span-3">
              <input type="radio" id="VKNR" value="VKNR" v-model="formShortSearch.searchtopicShort" class="hidden peer"
                 @click="clearFormShortISBN()" />
              <label for="VKNR"
                class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 rounded-l cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
                <div class="block">
                  <div class="text-lg font-semibold">
                    {{ $t("Search.VKNR") }}
                  </div>
                </div>
              </label>
            </li>            
          </ul>

          <div class="mt-2 grid gap-1 md:grid-cols-6">
            <div class="md:col-span-4" v-if="this.formShortSearch.searchtopicShort == 'ISBN'">
              <input
                class="w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
                placeholder="978-3" 
                v-model="formShortSearch.ISBNInput"
                @keyup="formShortSearch.validateISBNempty = false"
                @keydown.enter.prevent="submitShortSearch"
              />
            </div>
            <div class="md:col-span-4" v-if="this.formShortSearch.searchtopicShort == 'VKNR'">
              <input
                class="w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
                v-model="formShortSearch.VKNRInput"
                @keyup="formShortSearch.validateVKNRempty = false"
                @keydown.enter.prevent="submitShortSearch"
              />
            </div>
            <div class="md:col-span-2">
              <button @click="submitShortSearch()"
                class="w-full items-center justify-between text-lg px-2 py-1 rounded text-white bg-gray-500 cursor-pointer hover:text-white hover:bg-red-600">
               {{ $t("Search.quicksearch")}} <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
              </button>
            </div>
            <div class="md:col-span-6">
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateVKNRempty == true"
                >{{ $t("Search.Error.vknrEmpty") }}</span
              >      
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateISBNempty == true"
                >{{ $t("Search.Error.ISBNEmpty") }}</span
              >                        
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateVKNRInputSize == false"
                >{{ $t("Search.Error.vknrSize") }}</span
              >
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateVKNRInputNumbers == false"
                >{{ $t("Search.Error.vknrNumbers") }}</span
              >
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateVKNRInputStartwith == false"
                >{{ $t("Search.Error.vknrstartwith") }}</span
              >
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateISBNInputLength == false"
                >{{ $t("Search.Error.isbnLength") }}</span
              >
              <span
                class="block text-sm text-red-600"
                v-if="this.formShortSearch.validateISBNInputNumbers == false"
                >{{ $t("Search.Error.isbnOnlyNumbers") }}</span
              >
              <span
                class="block text-xs text-grey-900 italic"
                v-if="
                  this.formShortSearch
                    .validateVKNRInputStartwithRange100to174 == true
                "
                >{{ $t("Search.Hint.1") }}</span
              >
              <span
                class="block text-xs text-grey-900 italic"
                v-if="
                  this.formShortSearch
                    .validateVKNRInputStartwithRange175to179 == true
                "
                >{{ $t("Search.Hint.2") }}</span
              >
              <span
                class="block text-xs text-grey-900 italic"
                v-if="
                  this.formShortSearch
                    .validateVKNRInputStartwithRange180to184 == true
                "
                >{{ $t("Search.Hint.3") }}</span
              >
              <span
                class="block text-xs text-grey-900 italic"
                v-if="
                  this.formShortSearch
                    .validateVKNRInputStartwithRange185to189 == true
                "
                >{{ $t("Search.Hint.4") }}</span
              >
              <span
                class="block text-xs text-grey-900 italic"
                v-if="
                  this.formShortSearch
                    .validateVKNRInputStartwithRange200to599 == true
                "
                >{{ $t("Search.Hint.5") }}</span
              >
            </div>
          </div>
        </div>
      </form>

      <!---------------- end Short SEARCH ------------------------->
    </div>

    <!---------------- FULL SEARCH ------------------------->

    <div class="mt-12 bg-white p-4 mb-4 block">

      <!---------------- SEARCH TABS  ------------------------->
      <ul class="grid gap-1 md:grid-cols-6">
        <li>
          <input v-on:click="hideAllTabs(), showTabbusinesstype()" type="radio" id="formFullSearch-businesstype"
            name="formFullSearch.searchtopic" value="formFullSearch.businesstype" v-model="formFullSearch.searchtopic"
            class="hidden peer" />
          <label for="formFullSearch-businesstype"
            class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 rounded-l cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
            <div class="block">
              <div class="text-base font-semibold">
                {{ $t("Search.businesstype") }}
              </div>
            </div>
          </label>
        </li>
        <li>
          <input v-on:click="hideAllTabs(), showTabvlbtitle()" type="radio" id="formFullSearch-vlbtitle"
            name="formFullSearch.searchtopic" value="formFullSearch.vlbtitle" ref="tabvlbtitle"
            v-model="formFullSearch.searchtopic" :disabled="this.formFullSearch.businesstype != 'publisher'"
            class="hidden peer" />
          <label for="formFullSearch-vlbtitle"
            class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:bg-gray-400 peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
            <div class="block">
              <div class="text-base font-semibold">
                {{ $t("Search.vlbActiveTitle") }}
              </div>
            </div>
          </label>
        </li>
        <li>
          <input v-on:click="hideAllTabs(), showTabcompanyname()" type="radio" id="formFullSearch-companyname"
            name="formFullSearch.searchtopic" value="formFullSearch.companyname" v-model="formFullSearch.searchtopic"
            class="hidden peer" />
          <label for="formFullSearch-companyname"
            class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
            <div class="block">
              <div class="text-base font-semibold">
                {{ $t("Search.companyname") }}
              </div>
            </div>
          </label>
        </li>
        <li>
          <input v-on:click="hideAllTabs(), showTabregion()" type="radio" id="formFullSearch-region"
            name="formFullSearch.searchtopic" value="region" v-model="formFullSearch.searchtopic" class="hidden peer" />
          <label for="formFullSearch-region"
            class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
            <div class="block">
              <div class="text-base font-semibold">
                {{ $t("Search.region") }}
              </div>
            </div>
          </label>
        </li>
        <li>
          <input v-on:click="hideAllTabs(), showTabcodenumbers()" type="radio" id="formFullSearch-codenumber"
            name="formFullSearch.searchtopic" value="codenumber" v-model="formFullSearch.searchtopic"
            class="hidden peer" />
          <label for="formFullSearch-codenumber"
            class="inline-flex w-full items-center justify-between px-2 py-1 text-white bg-gray-500 rounded-r cursor-pointer peer-checked:bg-red-600 hover:text-white peer-checked:text-white hover:bg-red-600">
            <div class="block">
              <div class="text-base font-semibold">
                {{ $t("Search.codenumber") }}
              </div>
            </div>
          </label>
        </li>

      </ul>

      <!---------------- end SEARCH TABS  ------------------------->

      <!---------------- Customer Input ------------------------->

      <div id="customerinput" class="bg-white border border-gray-200 mt-2 rounded px-3 py-2" v-if="formShortSearch.shortsearchVal ||
        formFullSearch.businesstype ||
        formFullSearch.vlbtitleMIN ||
        formFullSearch.vlbtitleMAX ||
        formFullSearch.companyname ||
        formFullSearch.city ||
        formFullSearch.BAG ||
        formFullSearch.DOI ||
        formFullSearch.GLN ||
        formFullSearch.taxID
        ">
        <h3 class="font-semibold md:inline block">
          {{ $t("Search.YourInput") }}&nbsp;
        </h3>
        <div class="md:inline block">
          <div
            class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-500 inline mt-1"
            :class="formShortSearch.shortsearchVal ? 'd-block' : 'hidden'">
            <span class="font-semibold" v-if="formShortSearch.searchtopicShort">{{ formShortSearch.searchtopicShort
            }}:&nbsp;</span>
            <span>{{ formShortSearch.searchtopicShort }}&nbsp;</span>

            <span class="cursor-pointer">
              <font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="formShortSearch.shortsearchVal = null" />
            </span>
          </div>

          <div
            class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
            :class="formFullSearch.businesstype ? 'd-block' : 'hidden'">
            <span class="font-semibold">{{ $t("Search.businesstype") }}:&nbsp;</span>
            <span v-if="formFullSearch.businesstype == 'publisher'">{{ $t("Search.publishers") }}&nbsp;
            </span>
            <span v-if="formFullSearch.businesstype == 'bookstores'">{{ $t("Search.bookstores") }}&nbsp;
            </span>
            <span v-if="formFullSearch.businesstype == 'serviceprovider'">{{ $t("Search.serviceprovider") }}&nbsp;
            </span>
            <span v-if="formFullSearch.businesstype == 'distributor'">{{ $t("Search.distributor") }}&nbsp;
            </span>
            <span v-if="formFullSearch.businesstype == 'singleISBNpublisher'">{{ $t("Search.singleISBNpublisher") }}&nbsp;
            </span>
            <span class="cursor-pointer">
              <font-awesome-icon icon="fa-solid fa-window-close" size="lg" @click="formFullSearch.businesstype = null" />
            </span>
          </div>

          <div
            class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
            :class="formFullSearch.vlbtitleMIN || formFullSearch.vlbtitleMAX
                ? 'd-block'
                : 'hidden'
              ">
            <span class="font-semibold">{{ $t("Search.vlbActiveTitle") }}:&nbsp;</span>
            <span>{{ formFullSearch.vlbtitleMIN }}&nbsp; -&nbsp;
              {{ formFullSearch.vlbtitleMAX }} &nbsp;</span>
            <span class="cursor-pointer">
              <font-awesome-icon icon="fa-solid fa-window-close" size="lg" @click="
                (formFullSearch.vlbtitleMIN = null),
                (formFullSearch.vlbtitleMAX = null)
                " />
            </span>
          </div>

          <div
            class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
            :class="formFullSearch.companyname ? 'd-block' : 'hidden'">
            <span class="font-semibold">{{ $t("Search.companyname") }}:&nbsp;</span>
            <span>{{ formFullSearch.companyname }}&nbsp;</span>
            <span class="cursor-pointer">
              <font-awesome-icon icon="fa-solid fa-window-close" size="lg" @click="formFullSearch.companyname = null" />
            </span>
          </div>
          <div
            class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
            :class="formFullSearch.city ? 'd-block' : 'hidden'">
            <span class="font-semibold">{{ $t("Search.city") }}:&nbsp;</span>
            <span>{{ formFullSearch.city }}&nbsp;
              <span class="cursor-pointer">
                <font-awesome-icon icon="fa-solid fa-window-close" size="lg" @click="formFullSearch.city = null" />
              </span></span>
          </div>

          <!-- codenumbers -->
          <div class="block">
            <!--formFullSearch.GLN-->
            <div
              class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
              :class="formFullSearch.GLN ? 'd-block' : 'hidden'">
              <span class="font-semibold">{{ $t("Search.GLN") }}:&nbsp;</span>
              <span>{{ formFullSearch.GLN }}&nbsp;
                <span class="cursor-pointer">
                  <font-awesome-icon
                    icon="fa-solid fa-window-close"
                    size="lg"
                    @click="
                      (this.formFullSearch.GLN = null),
                      (this.formFullSearch.GLNInputSize = null)
                    "
                  /> </span
              ></span>
            </div>

            <!--formFullSearch.DOI-->
            <div
              class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
              :class="formFullSearch.DOI ? 'd-block' : 'hidden'">
              <span class="font-semibold">{{ $t("Search.DOI") }}:&nbsp;</span>
              <span>{{ formFullSearch.DOI }}&nbsp;
                <span class="cursor-pointer">
                  <font-awesome-icon
                    icon="fa-solid fa-window-close"
                    size="lg"
                    @click="
                      (this.formFullSearch.DOI = null),
                      (this.formFullSearch.DOIInputSize = null)
                    "
                  /> </span
              ></span>
            </div>

            <!--formFullSearch.taxID-->
            <div
              class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
              :class="formFullSearch.taxID ? 'd-block' : 'hidden'">
              <span class="font-semibold">{{ $t("Search.taxID") }}:&nbsp;</span>
              <span>{{ formFullSearch.taxID }}&nbsp;
                <span class="cursor-pointer">
                  <font-awesome-icon
                    icon="fa-solid fa-window-close"
                    size="lg"
                    @click="
                      (formFullSearch.taxID = null),
                        (formFullSearch.taxIDCheckDE = null),
                        (formFullSearch.taxIDCheckATU = null),
                        (formFullSearch.taxIDCheckCHE = null),
                        (formFullSearch.taxIDCheckDefault = null)
                    "
                  /> </span
              ></span>
            </div>

            <!--formFullSearch.BAG-->
            <div
              class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 inline mt-1"
              :class="formFullSearch.BAG ? 'd-block' : 'hidden'">
              <span class="font-semibold">{{ $t("Search.BAG") }}:&nbsp;</span>
              <span>
                <span v-if="formFullSearch.BAG == 'true'">{{ $t("Search.bagYes") }}&nbsp;
                </span>
                <span class="cursor-pointer">
                  <font-awesome-icon icon="fa-solid fa-window-close" size="lg" @click="formFullSearch.BAG = null" />
                </span></span>
            </div>
          </div>
          <!-- end codenumbers -->
        </div>
      </div>

      <!-- Customer Input REGIONS-------------->

      <div class="bg-white border-2 mt-2 rounded px-3 py-2" v-if="formFullSearch.showGermany ||
        formFullSearch.showAustria ||
        formFullSearch.showSwitzerland ||
        formFullSearch.showLiechtenstein
        ">
        <h3 class="font-semibold block">
          {{ $t("Search.YourRegions") }}&nbsp;
        </h3>
        <!-- Germany-->
        <div
          class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 block mt-1"
          :class="formFullSearch.showGermany ? 'd-block' : 'hidden'">
          <span class="font-semibold" :class="formFullSearch.showGermany ? 'd-block' : 'hidden'">{{ $t("Party.Germany")
          }}&nbsp;

            <span class="cursor-pointer mr-2"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="deselectCountry('D')" /></span>
          </span>
          <div class="inline" :class="formFullSearch.federalstatesDE.length ? 'd-block' : 'hidden'
            ">
            <span v-for="state in formFullSearch.federalstatesDE" :key="state">{{GermanyStates[state]}},&nbsp;</span>

            <span class="cursor-pointer"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="formFullSearch.federalstatesDE.length = 0" /></span>
          </div>
        </div>
        <!-- Austria-->
        <div
          class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 block mt-1"
          :class="formFullSearch.showAustria ? 'd-block' : 'hidden'">
          <span class="font-semibold" :class="formFullSearch.showAustria ? 'd-block' : 'hidden'">{{ $t("Party.Austria")
          }}&nbsp;

            <span class="cursor-pointer mr-2"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="deselectCountry('A')" /></span>
          </span>
          <div class="inline" :class="formFullSearch.federalstatesAU.length ? 'd-block' : 'hidden'
            ">
            <span v-for="state in formFullSearch.federalstatesAU" :key="state">{{AustriaStates[state]}},&nbsp;</span>
            <span class="cursor-pointer"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="formFullSearch.federalstatesAU.length = 0" /></span>
          </div>
        </div>
        <!-- Switzerland -->
        <div
          class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 block mt-1"
          :class="formFullSearch.showSwitzerland ? 'd-block' : 'hidden'">
          <span class="font-semibold" :class="formFullSearch.showSwitzerland ? 'd-block' : 'hidden'">{{
            $t("Party.Switzerland") }}&nbsp;
            <span class="cursor-pointer mr-2"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="deselectCountry('CH')" /></span></span>
          <div class="inline" :class="formFullSearch.federalstatesCH.length ? 'd-block' : 'hidden'
            ">
            <span v-for="state in formFullSearch.federalstatesCH" :key="state">{{SwitzerlandStates[state]}},&nbsp;</span>

            <span class="cursor-pointer"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="formFullSearch.federalstatesCH.length = 0" /></span>
          </div>
        </div>
        <!-- Liechtenstein -->
        <div
          class="bg-gray-100 text-black text-sm font-medium mr-2 px-2.5 py-0.5 rounded-sm border border-gray-700 block mt-1"
          :class="formFullSearch.showLiechtenstein ? 'd-block' : 'hidden'">
          <span class="font-semibold" :class="formFullSearch.showLiechtenstein ? 'd-block' : 'hidden'">{{
            $t("Party.Liechtenstein") }}&nbsp;
            <span class="cursor-pointer mr-2"><font-awesome-icon icon="fa-solid fa-window-close" size="lg"
                @click="deselectCountry('FL')" /></span>
          </span>
        </div>
      </div>

      <!-- End Customer Input REGIONS---------->

      <!---------------- end  Customer Input  ------------------------->

      <!---------------- TAB formFullSearch.businesstype ------------------------->
      <div id="tabbusinesstype" class="mt-3 mb-5" :class="tabbusinesstype ? 'd-block' : 'hidden'">
        <h3 class="font-semibold text-gray-900">
          {{ $t("Search.businesstype") }}:
        </h3>

        <!-- radiobuttons formFullSearch.businesstype-->
        <ul class="items-center text-sm font-medium text-gray-900 sm:flex">
          <li class="">
            <div class="flex items-center pl-3">
              <input id="bookstores" type="radio" value="bookstores" name="formFullSearch.businesstype"
                v-model="formFullSearch.businesstype" @change="checkVLBtitel()"
                class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700" />
              <label for="bookstores"
                class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900">{{
                  $t("Search.bookstores") }}</label>
            </div>
          </li>
          <li class="">
            <div class="flex items-center pl-3">
              <input id="publisher" type="radio" value="publisher" name="formFullSearch.businesstype"
                v-model="formFullSearch.businesstype" @change="checkVLBtitel()"
                class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700" />
              <label for="publisher"
                class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900">{{
                  $t("Search.publishers") }}</label>
            </div>
          </li>
          <li class="">
            <div class="flex items-center pl-3">
              <input id="singleISBNpublisher" type="radio" value="singleISBNpublisher" name="formFullSearch.businesstype"
                v-model="formFullSearch.businesstype" @change="checkVLBtitel()"
                class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700" />
              <label for="singleISBNpublisher"
                class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900">{{
                  $t("Search.singleISBNpublisher") }}</label>
            </div>
          </li>
          <li class="">
            <div class="flex items-center pl-3">
              <input id="serviceprovider" type="radio" value="serviceprovider" name="formFullSearch.businesstype"
                v-model="formFullSearch.businesstype" @change="checkVLBtitel()"
                class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700" />
              <label for="serviceprovider"
                class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900">{{
                  $t("Search.serviceprovider") }}</label>
            </div>
          </li>
          <li class="">
            <div class="flex items-center pl-3">
              <input id="distributor" type="radio" value="distributor" name="formFullSearch.businesstype"
                v-model="formFullSearch.businesstype" @change="checkVLBtitel()"
                class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700" />
              <label for="distributor"
                class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900">{{
                  $t("Search.distributor") }}</label>
            </div>
          </li>
        </ul>

        <!-- end radiobuttons -->
      </div>
      <!---------------- end TAB formFullSearch.businesstype ---------------------->

      <!---------------- TAB formFullSearch.vlbtitle ------------------------->
      <div id="tabvlbtitle" class="mt-3 mb-5" :class="tabvlbtitle ? 'd-block' : 'hidden'">
        <h3 class="font-semibold text-gray-900">
          {{ $t("Search.vlbActiveTitle") }}:
        </h3>

        <span>{{$t("Search.Min")}}&nbsp;</span>

        <input class="inline w-20 col-span-2 border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
           type="number" inputmode="numeric" v-model="formFullSearch.vlbtitleMIN"
          @blur="checkVLBtitelnumber()" />

        <span class="pl-4 inline-block">{{$t("Search.Max")}}&nbsp;</span>

        <input class="inline w-20 col-span-2 border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
          type="number" inputmode="numeric" v-model="formFullSearch.vlbtitleMAX"
          @blur="checkVLBtitelnumber()" />
      </div>
      <!---------------- end TAB formFullSearch.companyname ---------------------->

      <!---------------- TAB formFullSearch.companyname ------------------------->
      <div id="tabcompanyname" class="mt-3 mb-5" :class="tabcompanyname ? 'd-block' : 'hidden'">
        <h3 class="font-semibold text-gray-900">
          {{ $t("Search.companyname") }}:
        </h3>
        <input class="inline md:w-1/2 w-full col-span-2 border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
          v-model="formFullSearch.companyname" 
          @keydown.enter.prevent="submitFullSearch" 
          placeholder="Firmenname eingeben" />
      </div>
      <!---------------- end TAB formFullSearch.companyname ---------------------->

      <!---------------- TAB region ------------------------------->
      <div id="tabregion" class="mt-3" :class="tabregion ? 'd-block' : 'hidden'">
        <div class="w-full mb-4">
          <h3 class="font-semibold text-gray-900">{{ $t("Search.city") }}:</h3>
          <input class="inline md:w-1/2 w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
            v-model="formFullSearch.city"
            @keydown.enter.prevent="submitFullSearch" 
            placeholder="Stadt eingeben" />
        </div>

        <!-- CHECK buttons  Countries-->
        <div class="w-full mb-4">
          <div
            class="grid md:grid-cols-4 grid-cols-1 gap-1 p-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-sm">
            <div class="order-1">
              <label class="inline-flex w-full items-center">
                <input type="checkbox" value="D" ref="checkboxgermany" v-model="formFullSearch.country"
                  @change="changeCountryTab()"
                  class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                <span class="ml-2 cursor-pointer text-lg font-bold">{{
                  $t("Party.Germany")
                }}</span>
              </label>
            </div>
            <div class="order-3 md:order-2">
              <label class="inline-flex w-full items-center">
                <input type="checkbox" value="A" ref="checkboxaustria" v-model="formFullSearch.country"
                  @change="changeCountryTab()"
                  class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                <span class="ml-2 cursor-pointer text-lg font-bold">{{
                  $t("Party.Austria")
                }}</span>
              </label>
            </div>
            <div class="order-5 md:order-3">
              <label class="inline-flex w-full items-center">
                <input type="checkbox" value="CH" ref="checkboxswitzerland" v-model="formFullSearch.country"
                  @change="changeCountryTab()"
                  class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                <span class="ml-2 cursor-pointer text-lg font-bold">{{
                  $t("Party.Switzerland")
                }}</span>
              </label>
            </div>
            <div class="order-7 md:order-4">
              <label class="inline-flex w-full items-center">
                <input type="checkbox" value="FL" ref="checkboxliechtenstein" v-model="formFullSearch.country"
                  @change="changeCountryTab()"
                  class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                <span class="ml-2 cursor-pointer text-lg font-bold">{{
                  $t("Party.Liechtenstein")
                }}</span>
              </label>
            </div>
            <div class="order-2 md:order-5">

              <!-- Federal States Germany -->
              <div v-if="formFullSearch.showGermany == true">
                <div class="grid gap-1 grid-cols-1 mt-2">
                  <label class="inline-flex items-center" v-for="(stateName, stateCode) in GermanyStates" :key="stateCode">
                    <input type="checkbox" :value="stateCode" v-model="formFullSearch.federalstatesDE"
                      class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                    <span class="ml-2 cursor-pointer text-sm">{{ stateName }}</span>
                  </label>                                                                                                                                                                                                                                                 
                </div>
              </div>
              <!-- end Federal States Germany -->

            </div>
            <div class="order-4 md:order-6">

              <!-- Federal States Austria -->
              <div v-if="formFullSearch.showAustria == true">
                <div class="grid gap-1 grid-cols-1 mt-2">
                  <label class="inline-flex items-center" v-for="(stateName, stateCode) in AustriaStates" :key="stateCode">
                    <input type="checkbox" :value="stateCode" v-model="formFullSearch.federalstatesAU"
                      class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                    <span class="ml-2 cursor-pointer text-sm">{{ stateName }}</span>
                  </label>                  			  
                </div>
              </div>
              <!-- end Federal States Austria -->

            </div>
            <div class="order-6 md:order-7">

              <!-- Federal States Sitzerland -->
              <div v-if="formFullSearch.showSwitzerland == true">
                <div class="grid gap-1 grid-cols-1 mt-2">
                  <label class="inline-flex items-center" v-for="(stateName, stateCode) in SwitzerlandStates" :key="stateCode">
                    <input type="checkbox" :value="stateCode" v-model="formFullSearch.federalstatesCH"
                      class="form-checkbox border-2 border-gray-700 w-4 h-4 focus:border-2 focus:border-red-700 text-red-700 rounded-sm ring-0 ring-red-700 focus:ring-0 focus:ring-red-700 cursor-pointer" />
                    <span class="ml-2 cursor-pointer text-sm">{{ stateName }}</span>
                  </label>
                </div>
              </div>
              <!-- end Federal States Sitzerland -->

            </div>
            <div class="order-8">
              <!-- Federal States Liechtenstein -->
              <div v-if="formFullSearch.showLiechtenstein == true">
              <!-- I leave this, if we have it sometime-->
              </div>

              <!-- end Federal States Liechtenstein -->
            </div>
          </div>
        </div>

        <!-- end Radio buttons Countries-->
      </div>
      <!----------------  end TAB region ------------------------------->

     <!---------------- TAB codenumbers ------------------------->
     <div
        id="tabcodenumbers"
        class="mt-3 mb-5"
        :class="tabcodenumbers ? 'd-block' : 'hidden'"
      >
        <h3 class="font-semibold text-gray-900">
          {{ $t("Search.codenumber") }}:
        </h3>

        <div class="w-full mb-4">
          <div
            class="grid md:grid-cols-8 grid-cols-1 gap-1 p-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-sm"
          >
            <div class="grid-cols-none md:col-span-2 md:order-1 font-bold">
              {{ $t("Search.GLN") }}
            </div>
            <div class="grid-cols-none md:col-span-2 order-3 md:order-2">
              <span class="font-bold block"><a href="https://german-isbn.de/doi/der-doi" target="_blank"  rel="noopener" >{{ $t("Search.DOI") }}</a></span>
              <span class="font-light text-xs italic"
                >
              </span
              >
                </div>
            <div
              class="grid-cols-none md:col-span-3 order-5 md:order-3 font-bold"
            >
              {{ $t("Search.taxID") }}
            </div>
            <div
              class="grid-cols-none md:col-span-1 order-7 md:order-4 font-bold"
            >
              {{ $t("Search.BAG") }}
            </div>

            <div class="grid-cols-none md:col-span-2 order-2 md:order-5">
              <input
                class="inline w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
                v-model="formFullSearch.GLN"
                 @keydown.enter.prevent="submitFullSearch" 
                placeholder=""
                maxlength="13"
              />
              <span class="block text-sm text-red-600"
                v-if="this.formFullSearch.GLNInputSize == false">{{ $t("Search.Error.GLN") }}</span>
            </div>
            <div class="grid-cols-none md:col-span-2 order-4 md:order-6">
              <input
                class="inline w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
                v-model="formFullSearch.DOI"
                 @keydown.enter.prevent="submitFullSearch" 
                placeholder=""
                v-on:click="setDoiInput"
              />
              <span class="block text-sm text-red-600"
                v-if="this.formFullSearch.DOIInputSize == false">{{ $t("Search.Error.DOI") }}</span>
            </div>
            <div class="grid-cols-none md:col-span-3 order-6 md:order-7">
              <input
                class="inline w-full border outline-red-600 focus:ring-0 px-3 py-1 mr-0"
                v-model="formFullSearch.taxID"
                 @keydown.enter.prevent="submitFullSearch" 
                placeholder=""
              />
              <span class="block text-sm text-red-600" v-if="this.formFullSearch.taxIDCheckDE == false">{{ $t("Search.Error.taxIDDE") }}</span>
              <span class="block text-sm text-red-600" v-if="this.formFullSearch.taxIDCheckATU == false">{{ $t("Search.Error.taxIDATU") }}</span>
              <span class="block text-sm text-red-600" v-if="this.formFullSearch.taxIDCheckCHE == false">{{ $t("Search.Error.taxIDCHE") }}</span>
              <span class="block text-sm text-red-600" v-if="this.formFullSearch.taxIDCheckDefault == false">{{ $t("Search.Error.taxIDDefault") }}</span>
            </div>
            <div class="grid-cols-none md:col-span-1 order-8">
              <ul
                class="items-center text-sm font-medium text-gray-900 sm:flex"
              >
                <li class="">
                  <div class="flex items-center pl-3">
                    <input
                      id="bagYes"
                      type="checkbox"
                      value="true"
                      name="BAG"
                      v-model="formFullSearch.BAG"
                      class="peer w-4 h-4 bg-gray-400 border-gray-300 form-radio text-red-700"
                    />
                    <label
                      for="bagYes"
                      class="peer-checked:text-red-600 peer-checked:font-bold py-3 pr-3 ml-2 text-sm font-medium text-gray-900"
                      >{{ $t("Search.bagYes") }}</label
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!---------------- end TAB codenumbers ---------------------->

      <div class="block" v-if="tabcompanyname ||
        tabregion ||
        tabbusinesstype ||
        tabvlbtitle ||
        tabcodenumbers
        ">

        <button @click="submitFullSearch()"
          class="block items-center text-base px-3 py-1 mt-1 rounded disabled:cursor-not-allowed disabled:bg-gray-400 text-white bg-gray-500 cursor-pointer hover:text-white hover:bg-red-600">
          {{ $t("Search.startFullSearch") }}
        </button>
        <div class="mt-3 text-sm text-blue-800" :class="emptySubmit ? 'd-block' : 'hidden'">{{$t("Search.YouMusselectSomething")}}</div>   
        <button @click="clearAllSearches()"
          class="block items-center text-xs px-3 py-1 mt-5 rounded disabled:cursor-not-allowed disabled:bg-gray-400 text-black border border-gray-500 cursor-pointer hover:text-white hover:bg-red-600 hover:border-red-600">
          {{ $t("Search.clearAll") }}
        </button>   
        <div class="mt-3 text-sm text-blue-800" :class="allFormInputsAreCleared ? 'd-block' : 'hidden'">{{$t("Search.AllInputsCleared")}}</div> 
      </div>

    </div>
  </div>


</template>