import { defineStore } from 'pinia'
import router from '../router'

export const usePartyStore = defineStore ('party', {

    state: () => ({
        party: null, //store the current party only
        error: null,
        page: null, // If more than 1 search result, we get a pagination - stored here, to not get confused
    }),

    /* The search actions automatically redirect to the result - either the entry if it's exactly 1 result,
     * or to the not found page if 0 results are found, or to the overview page if more results.
     * In Addition, the result (iff 1) is loaded into cache.
     */
    actions: {
        /* Search for locs id, skip fetch if already there -> avoid endless loop */
        async searchParty (locsId) {
            if (this.party && this.party.id == locsId) {
                return;
            }
            if (locsId == null) {
                return;
            }
            
            this.clearCache();
            if (!/[a-f0-9 ]{32}/.test(locsId)) {
                this.notFound();
                return;
            }
            this.doFetch(process.env.VUE_APP_URL + '/AdbParties/locsid/exact/' + locsId);
        },

        /* Search for the MVB-ID */
        async searchMvbId (mvbId) {
            if (mvbId == null) {
                return;
            }
            this.clearCache();
            
            if (!/\d+/.test(mvbId)) {
                this.notFound();
                return;
            }
            
            this.doFetch(process.env.VUE_APP_URL + '/AdbParties/mvbid/exact/' + mvbId);
        },

        /* Search for the EXACT companyName
         * Might have more than 1 result, than redirect to search page, else to the result.
         * Additional info for pagination, optional
         */
        async searchNameExact(companyName, pageNumber, pageSize) {
            if (companyName == null || companyName == '') {
                return;
            }

            this.clearCache();

            var fetchUrl = process.env.VUE_APP_URL + '/AdbParties/nameExact?companyName=' + encodeURIComponent(companyName);
            if (pageNumber) {
                // Backend uses 0 based index
                fetchUrl = fetchUrl + '&page=' + (pageNumber - 1);
            }
            if (pageSize) {
                fetchUrl = fetchUrl + '&size=' + pageSize;
            }

            let page;
            try {
            page  = await fetch(fetchUrl)
                .then((response) => response.json());
            }
            catch (error) {
                this.error = error;
                this.handleFetchError();
                return;
            }

            if (page.totalElements == 0) {
                this.notFound();
                return;
            }

            if (page.totalElements == 1) {
                this.party = JSON.parse(page.content[0]);
                router.push({
                    name: "partySearch",
                    params: {
                      id: this.party.id,
                    },
                });
                return;
            }

            this.page = page;
            this.page.content = page.content.map(JSON.parse);

            //For the target page; it might need the query e. g. for pagination: next page
            const query = {companyName: companyName};
            if (pageNumber) {
                query.page = pageNumber;
            }
            if (pageSize) {
                query.size = pageSize;
            }
            router.push({
                name: "searchresult",
                query: query,
            });

        },

        /* Search for VKNR, creditor and debitor */
        async searchVknr (vknr) {
            if (vknr == null) {
                return;
            }
            this.clearCache();
            
            if (!/\d+/.test(vknr)) {
                this.notFound();
                return;
            }
            
            this.doFetch(process.env.VUE_APP_URL + '/AdbParties/vknr/' + vknr);
        },

        /* ADB full search
         * searchParams: Map<key: String, value: String> of search parameteres, used by HeaderLogoSearch and defined there.
         *      Must not be empty and contain at least one entry.
         * pageNumber: int, used for pagination, default: 0 (defined in backend)
         * pageSize: int, used for pagination, default: 20 (defined in backend)
         * fullEntries: boolean, search full entries (true) vs. search not full entries (false). Default: true (defined in backend)
         *      If true (or not set) and nothing is found, automatically a new search with fullEntries=false will be made.
         */
        async fullSearch (searchParams, pageNumber, pageSize, fullEntries) {
            if (searchParams == null || searchParams.size == 0) {
                return;
            }
            this.clearCache();

            var fetchUrl = process.env.VUE_APP_URL + '/AdbParties/fullSearch?searchParams=' + encodeURIComponent(JSON.stringify(Object.fromEntries(searchParams)));

            if (pageNumber) {
                // Backend uses 0 based index
                fetchUrl = fetchUrl + '&page=' + (pageNumber - 1);
            }
            if (pageSize) {
                fetchUrl = fetchUrl + '&size=' + pageSize;
            }
            if (fullEntries != undefined) {
                fetchUrl = fetchUrl + '&fullEntries=' + fullEntries;
            }

            //Can't use doFetch function below, because that handles only 1 result
            let page;
            try {
                page = await fetch(fetchUrl)
                    .then((response) => response.json());
            }
            catch (error) {
                this.error = error;
                this.handleFetchError();
                return;
            }

            if (page.totalElements == 0) {
                if (fullEntries == false) {
                    this.notFound();
                    return;
                }
                // else; fullEntries == true or undefined (default is true)
                this.fullSearch(searchParams, pageNumber, pageSize, false);
                return;
            }

            /* Problems if this was the full entry search, but there are results for moreResults.
            if (page.totalElements == 1) {
                this.party = JSON.parse(page.content[0]);
                router.push({
                    name: "partySearch",
                    params: {
                      id: this.party.id,
                    },
                });
                return;
            }
            */

            this.page = page;
            this.page.content = page.content.map(JSON.parse);

            //For the target page; it might need the query e. g. for pagination: next page
            const query = {searchParams: JSON.stringify(Object.fromEntries(searchParams))};
            if (pageNumber) {
                query.page = pageNumber;
            }
            if (pageSize) {
                query.size = pageSize;
            }
            if (fullEntries == false) {
                query.moreEntries = true;
            }
            router.push({
                name: "searchresult",
                query: query,
            });

            return;
        },

        /* Search for ISBN, including prefix */
        async searchISBN (isbn, pageNumber, pageSize, fullEntries) {
            if (isbn == null) {
                return;
                    }
            this.clearCache();
        
            if (!/\d+/.test(isbn)) {
                this.notFound();
                return;
            }
        
            var fetchUrl = process.env.VUE_APP_URL + '/AdbParties/isbnSearch?isbn=' + encodeURIComponent(isbn);
            if (pageNumber) {
                // Backend uses 0 based index
                fetchUrl = fetchUrl + '&page=' + (pageNumber - 1);
            }
            if (pageSize) {
                fetchUrl = fetchUrl + '&size=' + pageSize;
            }
            if (fullEntries != undefined) {
                fetchUrl = fetchUrl + '&fullEntries=' + fullEntries;
            }

            //Can't use doFetch function below, because that handles only 1 result
            let page;
            try {
                page = await fetch(fetchUrl)
                    .then((response) => response.json());
            }
            catch (error) {
                this.error = error;
                this.handleFetchError();
                return;
            }


            if (page.totalElements == 0) {
                if (fullEntries == false) {
                    this.notFound();
                    return;
                }
                // else; fullEntries == true or undefined (default is true)
                this.searchISBN(isbn, pageNumber, pageSize, false);
                return;
            }

            this.page = page;
            this.page.content = page.content.map(JSON.parse);

            //For the target page; it might need the query e. g. for pagination: next page
            const query = {isbn: isbn};
            if (pageNumber) {
                query.page = pageNumber;
            }
            if (pageSize) {
                query.size = pageSize;
            }
            if (fullEntries == false) {
                query.moreEntries = true;
            }

            router.push({
                name: "searchresult",
                query: query,
            });

            return;
        },
        

        /* Also locked entries */
        notFound() {
            this.clearCache();
            router.replace("/notFound");
        },

        /* The following functions are for internal usage, no need to call from external. */

        handleFetchError() {
            if (this.error == null) {
                this.notFound();
                return;
            }

            //E. g. no search result found, but also connection broken...
            if (this.error instanceof SyntaxError) {
                this.notFound();
                return;
            }

            //TODO: Handle this different, but for developing it seems great to do it like this.
            alert(this.error);
            throw this.error;
          },

        clearCache() {
            this.party = null;
            this.error = null;
            this.page = null;
        },

        async doFetch(fetchUrl) {
            try {
                this.party = await fetch(fetchUrl)
                    .then((response) => response.json());
            }
            catch (error) {
                this.error = error;
                this.party = null;
                this.handleFetchError();
                return;
            }
            if (this.party == null) {
                this.notFound();
                return;
            }

            // Locked entries
            if ( !this.party.businessType) {
                this.notFound();
                return;
            }
            if (this.party.archiveDateAdb && this.party.businessType != '1' && this.party.businessType != '11') {
                this.notFound();
                return;
            }
            if (this.party.lockKey && this.party.lockKey.split(' ').includes('9')) {
                this.notFound();
                return;
            }
            const ALLOWED_COUNTRIES = ['D', 'A', 'CH', 'FL'];
            if (this.party.postalAddress.country && !ALLOWED_COUNTRIES.includes(this.party.postalAddress.country)) {
                this.notFound();
                return;
            }
            const ALLOWED_BUSINESS_TYPES = ['1', '2', '3', '11', '12'];
            if ( !ALLOWED_BUSINESS_TYPES.includes(this.party.businessType)) {
                this.notFound();
                return;
            }

            this.sortStoreBranches();
            
            let id = this.party.id;

            router.push({
                name: "partySearch",
                params: {
                  id: id,
                },
            });
        },

        /* Bring the store branches of the single store in this.party.storeBranches
         * into the order defined in ticket #34774, works in place.
         */
        sortStoreBranches() {
            if (! this.party.storeBranches) {
                return;
            }

            this.party.storeBranches.sort((a, b) => {
                // SIC! Ticket #34774 says companyName is not relevant for sorting
                
                if (a.postalAddress.cityStreet < b.postalAddress.cityStreet) {
                    return -1;
                }
                if (a.postalAddress.cityStreet > b.postalAddress.cityStreet) {
                    return 1;
                }

                if (a.postalAddress.street1 < b.postalAddress.street1) {
                    return -1;
                }
                if (a.postalAddress.street1 > b.postalAddress.street1) {
                    return 1;
                }

                return 0;
            });
        }
    },
},);
