export default {
  "State.AUT-B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burgenland"])},
  "State.AUT-K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kärnten"])},
  "State.AUT-NÖ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederösterreich"])},
  "State.AUT-OÖ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberösterreich"])},
  "State.AUT-S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salzburg"])},
  "State.AUT-St": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steiermark"])},
  "State.AUT-T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirol"])},
  "State.AUT-V": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voralberg"])},
  "State.AUT-W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wien"])},
  "State.CHE-AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aargau"])},
  "State.CHE-AI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appenzell-Innerrhoden"])},
  "State.CHE-AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appenzell-Ausserrohoden"])},
  "State.CHE-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bern"])},
  "State.CHE-BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basel-Land"])},
  "State.CHE-BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basel"])},
  "State.CHE-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiburg"])},
  "State.CHE-GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genf"])},
  "State.CHE-GL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glarus"])},
  "State.CHE-GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graubünden"])},
  "State.CHE-JU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jura"])},
  "State.CHE-LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luzern"])},
  "State.CHE-NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuenburg"])},
  "State.CHE-NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nidwalden"])},
  "State.CHE-OW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obwalden"])},
  "State.CHE-SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sankt Gallen"])},
  "State.CHE-SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaffhausen"])},
  "State.CHE-SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solothurn"])},
  "State.CHE-SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwyz"])},
  "State.CHE-TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thurgau"])},
  "State.CHE-TI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tessin"])},
  "State.CHE-UR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uri"])},
  "State.CHE-VD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waadt"])},
  "State.CHE-VS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis"])},
  "State.CHE-ZG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zug"])},
  "State.CHE-ZH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zürich"])},
  "State.DE-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
  "State.DE-BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brandenburg"])},
  "State.DE-BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baden-Württemberg"])},
  "State.DE-BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayern"])},
  "State.DE-HB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bremen"])},
  "State.DE-HE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessen"])},
  "State.DE-HH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
  "State.DE-MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecklenburg-Vorpommern"])},
  "State.DE-NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedersachsen"])},
  "State.DE-NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordrhein-Westfalen"])},
  "State.DE-RP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rheinland-Pfalz"])},
  "State.DE-SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen-Anhalt"])},
  "State.DE-SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleswig-Holstein"])},
  "State.DE-SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saarland"])},
  "State.DE-SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen"])},
  "State.DE-TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thüringen"])}
}